import React, { ComponentType } from 'react';
import { useNavigate } from 'react-router-dom';
import { uuidv4 } from '@firebase/util';
import { yupResolver } from '@hookform/resolvers/yup';
import { message } from 'antd';
import { findIndex } from 'lodash';
import { filter, map } from 'modern-async';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { uploadFile } from 'services/functions';
import uploadObject from 'services/functions/upload-object';

// import { clothTypes } from 'utils/subcategory-mapping';
import * as Constants from '../../templates-main/constants';
import * as Types from '../types';
import { Api } from '..';

import { updateSchema } from './schema';
// import { deleteAndReuploadFirebaseFile } from 'services/functions/upload-file';

interface UpdateProps {
  defaultValues?: Partial<Types.IForm.Update>;
  onSuccess?: (values: Types.IForm.Update) => void;
  onError?: (values: Types.IApi.Update.Error) => void;
  component: ComponentType<UseFormReturn<Types.IForm.Update>>;
  item: Types.IForm.Update;
}

const Update: React.FC<UpdateProps> = ({ onSuccess, onError, defaultValues = {}, component: Component, item }) => {
  const navigate = useNavigate();
  // const { user } = useAuth();

  const queryClient = useQueryClient();

  const data = useForm<Types.IForm.Update>({
    resolver: yupResolver(updateSchema),
    defaultValues: {
      id: item?.id,
      gender: item?.gender,
      fabrics: item?.fabrics,
      title: item?.title,
      title_ru: item?.title_ru,
      alias: item?.alias,
      name: item?.name,
      ispublic: item?.ispublic,
      category: item?.category,
      garmenttype: item?.garmenttype,
      subcategory: item?.subcategory,
      variants: item?.variants,
      options: item?.options,
      images: item?.images,
      silhouette: item?.silhouette,
      materials: item?.materials,
      requires_styled_simulation: item?.requires_styled_simulation
    }
  });

  const mutate = useMutation<Types.IForm.Update, Types.IApi.Update.Error, Types.IApi.Update.Request, any>(
    async (values: any) => {
      console.log('final = ', values);
      await Api.Template(values);

      return values;
    },
    {
      onError: (err: any) => {
        message.error(err.data.error);
        onError?.(err);
      },
      onSuccess: values => {
        onSuccess?.(values);

        queryClient.invalidateQueries([Constants.TEMPLATES, 'list']);
        message.success('Template successfully updated');
        navigate('/templates');
      }
    }
  );

  const onSubmit = async (data: Types.IForm.Update) => {
    const storagePathId = data?.id ? data?.id : uuidv4();

    if (data?.images.length) {
      await map(data?.images, async (img: any, index: number) => {
        if (img.type) {
          await uploadFile(`templates/${storagePathId}/images`, img.originFileObj).then((e: any) => {
            data.images[index] = { id: img.uid, url: e };
          });
        } else {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          img.uid ?? delete img.uid;
        }
        // data.images[index] = { id: img.id, url: await deleteAndReuploadFirebaseFile(img.url, storagePathId) };
      });
    }

    await map(Object.keys(data.silhouette), async (side: any, index: number) => {
      // @ts-ignore
      if (data.silhouette[side].name) {
        // @ts-ignore
        await uploadFile(`templates/${storagePathId}/sillhoute`, data.silhouette[side]).then((e: any) => {
          // @ts-ignore
          data.silhouette[side] = e;
        });
      }
    });

    if (data?.materials.length) {
      await map(data?.materials, async (material: any, index: number) => {
        await map(await filter(Object.keys(material), (m: any) => m.includes('Map')), async (m: any) => {
          if (material[m].type) {
            await uploadFile(`templates/${storagePathId}/textures`, material[m]).then(async (e: any) => {
              // @ts-ignore
              data.materials[index][m] = e;
            });
          }
          return material;
        });
      });
    }

    const SIndex = findIndex(data?.variants, x => x.options.includes('S'));

    // @ts-ignore
    if (data?.variants[SIndex].objectUrl.name) {
      // @ts-ignore
      await uploadObject(`garments/${data.name}`, `${data.name}.obj`, data?.variants[SIndex].objectUrl).then(async (e: string) => {
        data.variants[SIndex].objectUrl = e;
        // TO-DO: Fix silhoutte generation on new objects
        // const formData = new FormData();

        // formData.append('garment', `${data.id ? data.alias : data.name}`);

        // await Api.GenerateSilhouette(formData).then((e: any) => {
        //   if (e.data.front) {
        //     data.silhouette.front = `${process.env.REACT_APP_BASE_URL}/${e.data.front}`;
        //     data.silhouette.back = `${process.env.REACT_APP_BASE_URL}/${e.data.back}`;
        //   }
        // });
      });
    }

    // @ts-ignore
    if (data?.variants[SIndex].objectUrlDefaultPose.name) {
      // @ts-ignore
      await uploadObject(`garments/${data.name}`, `${data.name}_default_pose.obj`, data?.variants[SIndex].objectUrlDefaultPose).then(
        async (e: string) => {
          data.variants[SIndex].objectUrlDefaultPose = e;
          // TO-DO: Fix silhoutte generation on new objects
          // const formData = new FormData();

          // formData.append('garment', `${data.id ? data.alias : data.name}`);

          // await Api.GenerateSilhouette(formData).then((e: any) => {
          //   if (e.data.front) {
          //     data.silhouette.front = `${process.env.REACT_APP_BASE_URL}/${e.data.front}`;
          //     data.silhouette.back = `${process.env.REACT_APP_BASE_URL}/${e.data.back}`;
          //   }
          // });
        }
      );
    }

    // @ts-ignore
    if (data?.variants[SIndex].zrpjProjectUrl.name) {
      // @ts-ignore
      await uploadObject(`garments/${data.name}`, `${data.name}.zprj`, data?.variants[SIndex].zrpjProjectUrl).then((e: string) => {
        data.variants[SIndex].zrpjProjectUrl = e;
      });
    }

    // @ts-ignore
    if (data?.variants[SIndex].zrpjProjectUrlDefaultPose.name) {
      // @ts-ignore
      await uploadObject(`garments/${data.name}`, `${data.name}_default_pose.zprj`, data?.variants[SIndex].zrpjProjectUrlDefaultPose).then(
        (e: string) => {
          data.variants[SIndex].zrpjProjectUrlDefaultPose = e;
        }
      );
    }

    const formData = new FormData();

    formData.append('garment', `${data.id ? data.name : data.name}`);

    await Api.GenerateSilhouette(formData).then((e: any) => {
      if (e.data.front) {
        data.silhouette.front = `${process.env.REACT_APP_BASE_URL}/${e.data.front}`;
        data.silhouette.back = `${process.env.REACT_APP_BASE_URL}/${e.data.back}`;
      }
    });

    await mutate.mutateAsync({
      ...(data.id && { id: data.id }),
      alias: data.id ? data.alias : data.name,
      title: data.title,
      title_ru: data.title_ru,
      name: data.name,
      category: data.category,
      subcategory: data.subcategory,
      options: data.options,
      fabrics: data.fabrics,
      garmenttype: data.garmenttype,
      materials: data.materials,
      ispublic: data.ispublic,
      images: data.images,
      gender: data.gender,
      variants: data.variants,
      silhouette: data.silhouette,
      requires_styled_simulation: data.requires_styled_simulation
    });
  };

  return (
    <form onSubmit={data.handleSubmit(onSubmit)}>
      <Component {...data} />
    </form>
  );
};

export default Update;
