import { ReactNode, useEffect, useState } from 'react';
import { InputProps as BaseInputProps } from 'antd/lib/input';
import cx from 'classnames';
import { Control, Controller, ControllerRenderProps } from 'react-hook-form';

import { Icon } from 'components';

import cls from './password.module.scss';

export interface PasswordProps extends Pick<BaseInputProps, 'disabled' | 'placeholder' | 'maxLength'> {
  className?: string;
  errorMsg?: string;
  control: Control<any>;
  name: string;
  suffix?: string;
  prefix?: ReactNode;
  password?: boolean;
  render?: (field: ControllerRenderProps) => React.ReactElement;
}

const Password: React.FC<PasswordProps> = ({
  placeholder,
  errorMsg,
  className,
  control,
  name,
  suffix,
  prefix,
  render,
  disabled,
  password,
  ...inputArgs
}) => {
  const [isFocused, setFocused] = useState(false);
  const [hide, setHide] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line no-underscore-dangle
    setFocused(!!control._defaultValues.name || !!control._formValues.name);
  }, []);

  return (
    <div className={cls.wrapper}>
      <div className={cx(cls.container, errorMsg && cls.error, isFocused && cls.active, disabled && cls.disabled)}>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <>
              <input
                className={cx(cls.input, prefix && cls['is-prefix'], className)}
                placeholder={placeholder}
                onFocus={() => setFocused(true)}
                disabled={disabled}
                type={hide ? 'string' : 'password'}
                id={name}
                {...inputArgs}
                {...field}
                onBlur={e => {
                  field.onBlur();
                  setFocused(!!e.target.value);
                }}
              />
              {render && render(field)}
            </>
          )}
        />
        <label
          htmlFor={name}
          // @ts-ignore
          placeholder={placeholder}
          className={cx(cls.label, errorMsg && cls.error, (isFocused || disabled) && cls.active)}
        >
          {placeholder}
        </label>
      </div>
      <span className={cls.password}>
        <Icon name={hide ? 'reveal' : 'hide'} size={20} onClick={() => setHide(h => !h)} />
      </span>
      {errorMsg && (
        <div className={cls.message}>
          <Icon name="exclamation" /> <span>{errorMsg}</span>
        </div>
      )}
    </div>
  );
};

export default Password;
