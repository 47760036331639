import React, { ReactNode, useEffect, useState } from 'react';
import { Select } from 'antd';
import { InputProps as BaseInputProps } from 'antd/lib/input';
import cx from 'classnames';
import { Icon } from 'comfi-ui-kit/lib/components';
import { SelectOption } from 'pages/template-editor/template-editor';
import { Control, Controller, ControllerRenderProps } from 'react-hook-form';

import cls from './tag-input.module.scss';

export interface InputProps extends Pick<BaseInputProps, 'type' | 'disabled' | 'placeholder' | 'maxLength' | 'min' | 'pattern' | 'step'> {
  className?: string;
  errorMsg?: string;
  control: Control<any>;
  name: string;
  suffix?: ReactNode;
  prefix?: ReactNode;
  render?: (field: ControllerRenderProps) => React.ReactElement;
  options: SelectOption[];
  multiple?: boolean;
}

const TagInput: React.FC<InputProps> = ({
  multiple = false,
  placeholder,
  errorMsg,
  className,
  control,
  name,
  suffix,
  prefix,
  render,
  disabled,
  maxLength,
  options,
  type,
  step,
  ...inputArgs
}) => {
  const [isFocused, setFocused] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line no-underscore-dangle
    setFocused(!!control._defaultValues.name || !!control._formValues.name);
  }, []);

  return (
    <div className={cls.wrapper}>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className={cx(cls.container, errorMsg && cls.error, isFocused && cls.active, disabled && cls.disabled)}>
        <Controller
          control={control}
          name={name}
          render={({ field }) => (
            <>
              <Select
                mode="tags"
                maxTagCount={1}
                className={cx(cls.input, prefix && cls['is-prefix'], className)}
                placeholder={placeholder}
                onFocus={() => setFocused(true)}
                disabled={disabled}
                maxLength={maxLength}
                value={field.value}
                options={options}
                id={name}
                {...inputArgs}
                onDeselect={(e: any) => {
                  if (!multiple) field.onChange('');
                }}
                onBlur={e => {
                  field.onBlur();
                  setFocused(false);
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  let newValue;

                  console.log('newValue', e);
                  if (!multiple) {
                    /* @ts-ignore */
                    newValue = e.length ? e[e.length - 1] : undefined;
                  } else {
                    newValue = e;
                  }

                  field.onChange(newValue);
                  console.log('field.value', field.value);
                }}
              />
              {render && render(field)}
            </>
          )}
        />
        {prefix && <span className={cx(cls.prefix, isFocused && cls.active)}>{prefix}</span>}
        <label
          htmlFor={name}
          // @ts-ignore
          placeholder={placeholder}
          className={cx(cls.label, errorMsg && cls.error, (isFocused || disabled) && cls.active)}
        >
          {placeholder}
        </label>
      </label>
      {suffix && <span className={cls.text}>{suffix}</span>}
      {errorMsg && (
        <div className={cls.message}>
          <Icon name="exclamation" /> <span>{errorMsg}</span>
        </div>
      )}
    </div>
  );
};

export default TagInput;
