import React, { ReactNode, useEffect, useState } from 'react';
import { InputProps as BaseInputProps } from 'antd/lib/input';
import cx from 'classnames';
import { Icon } from 'comfi-ui-kit/lib/components';
import { Control, Controller, ControllerRenderProps } from 'react-hook-form';

import cls from './input.module.scss';

export interface InputProps extends Pick<BaseInputProps, 'type' | 'disabled' | 'placeholder' | 'maxLength' | 'min' | 'pattern' | 'step'> {
  className?: string;
  errorMsg?: string;
  control: Control<any>;
  name: string;
  suffix?: ReactNode;
  prefix?: ReactNode;
  withCount?: boolean;
  render?: (field: ControllerRenderProps) => React.ReactElement;
}

const Input: React.FC<InputProps> = ({
  placeholder,
  errorMsg,
  className,
  control,
  name,
  suffix,
  prefix,
  render,
  disabled,
  maxLength,
  withCount = false,
  type,
  step,
  ...inputArgs
}) => {
  const [isFocused, setFocused] = useState(false);
  const [count, setCount] = useState<number>(maxLength ?? 0);

  useEffect(() => {
    // eslint-disable-next-line no-underscore-dangle
    setFocused(!!control._defaultValues.name || !!control._formValues.name);
  }, []);

  return (
    <div className={cls.wrapper}>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className={cx(cls.container, errorMsg && cls.error, isFocused && cls.active, disabled && cls.disabled)}>
        <Controller
          control={control}
          name={name}
          render={({ field }) => (
            <>
              <input
                className={cx(cls.input, prefix && cls['is-prefix'], className)}
                placeholder={placeholder}
                onFocus={() => setFocused(true)}
                disabled={disabled}
                maxLength={maxLength}
                step={step}
                id={name}
                {...inputArgs}
                {...field}
                onBlur={e => {
                  setCount(maxLength! - e.target.value.length!);
                  field.onBlur();
                  setFocused(!!e.target.value);
                }}
                type={type}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (maxLength) {
                    const counts = e.target.value.length;

                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                    setCount(maxLength - counts);
                    field.onChange(e);
                  }

                  if (!maxLength) {
                    field.onChange(e);
                  }
                }}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const max = maxLength ?? 0;

                  if (e.target.getAttribute('type') === 'number') {
                    if (e.target.value.endsWith('-')) return;

                    if (max && Number(e.target.value) > max) {
                      e.target.value = e.target.value.slice(0, max);
                      setCount(max - e.target.value.length);
                    }
                  }
                }}
              />
              {render && render(field)}
            </>
          )}
        />
        {prefix && <span className={cx(cls.prefix, isFocused && cls.active)}>{prefix}</span>}
        <label
          htmlFor={name}
          // @ts-ignore
          placeholder={placeholder}
          className={cx(cls.label, errorMsg && cls.error, (isFocused || disabled) && cls.active)}
        >
          {placeholder}
        </label>
      </label>
      {suffix && <span className={cls.text}>{suffix}</span>}
      {errorMsg && (
        <div className={cls.message}>
          <Icon name="exclamation" /> <span>{errorMsg}</span>
        </div>
      )}
      {withCount && <div className={cls.count}>{count} characters left</div>}
    </div>
  );
};

export default Input;
